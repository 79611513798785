
import React, { Component } from 'react';
export default class PageNotFound extends Component {


    
    render() { 
        window.pushDataLayer(null, true)
        return(
            <div class="bg-white">
                <div class="text-brandPink text-4xl text-center p-8 font-teleextrabold">404 – OOPS!</div>
                <div class="text-brandPink text-2xl text-center p-4  font-telebold">This is not the page you were looking for. </div>
                <div class="text-charcoal text-lg text-center font-telenormal">If you are in the store, please rescan the QR code or ask a Mobile Expert for help.</div>
                <div class="text-charcoal text-lg text-center pb-10 font-telenormal">Or visit <a href="https://t-mobile.com">T-Mobile.com</a> for info on other available audio products.</div>
            </div>
        )
    }
}