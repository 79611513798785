import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import HeadphoneActiveImage from '../../assets/images/Headphone-Active.svg';
import HeadphoneInactiveImage from '../../assets/images/Headphone-Inactive.svg'

import EarbudActiveImage from '../../assets/images/EarBud-Active.svg';
import EarbudInactiveImage from '../../assets/images/Earbud-Inactive.svg'
import styles from './productTypeSelection.module.scss';

export default class ProductTypeSelection extends Component {
    constructor(props) {
        super(props);
        this.setState(this.state)
    }

    componentWillReceiveProps(nextProps) {
        
        this.forceUpdate();
    }


    render() {
        const productType = document.location.pathname.split('/')[1];

        return (

            <div className={'grid grid-cols-2 justify-center p-2 pt-8 items-center justify-items-center'}>
                <div className="justify-center pr-4">
                    <Link className="self-end" to={`/headphones?storeID=${this.props.storeNumber}`} key={`headphone_selector`} >
                        <img data-gtm-category="engage"  data-gtm-action="toggle" data-gtm-label="headphones" alt="Headphones" className={"product-filter block justify-center min-w-full h-14"} src={(productType === "headphones") ? HeadphoneActiveImage : HeadphoneInactiveImage} />
                        <div data-gtm-category="engage"  data-gtm-action="toggle" data-gtm-label="headphones"  style={{textUnderlineOffset:"0.2em"}}  className={`product-filter  categorySelection ${(productType === "headphones") ? "text-brandPink underline" : "text-darkgray"} pt-4 text-2xl font-teleregular`} >Headphones</div>
                    </Link>
                </div>
                <div className="justify-center pl-4">
                    <Link className="self-end" to={`/earbuds?storeID=${this.props.storeNumber}`} key={`earbud_selector`} >
                        <img data-gtm-category="engage"  data-gtm-action="toggle" data-gtm-label="earbuds"  alt="Earbuds" className="product-filter  block justify-center min-w-full h-14" src={(productType === "earbuds") ? EarbudActiveImage : EarbudInactiveImage} />
                        <div data-gtm-category="engage"  data-gtm-action="toggle" data-gtm-label="earbuds"  style={{textUnderlineOffset:"0.2em"}} className={`product-filter  categorySelection ${(productType === "earbuds") ? "text-brandPink underline" : "text-darkgray"} pt-4  text-2xl  font-teleregular ${styles.selectedCategory}`}>Earbuds</div>
                    </Link>
                </div>
            </div>        
        )
    }

}