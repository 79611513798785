import { React, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from '../components/Header';
import AudioCatalog from './AudioCatalog'
import ProductDetails from './ProductDetails'
import PageNotFound from './PageNotFound'
import { storeListQuery, storeQuery, productMediaQuery } from './ContentfulQueries'
import DebugRouter from '../components/DebugRouter'
import styles from './App.module.scss';
import Footer from '../components/Footer';

//const SPACE_ID = 'gb6ctpiwoktj';
//const ACCESS_TOKEN = '85pDX6u03QacEfmhVfmo6Av9e-LfAD2DMeOcFq1GrCk';
var SPACE_ID = "vt7ewpoj2253";
var ACCESS_TOKEN = "JKn9qI_F9ppxXTnPHJjW8BzslmQpLTtQwFRUsHHckV8";
var showPreviewContent = false;






var storeNumber = window.storeID;
var productType = 'H';
var storeContent = null;

var headphoneComponent;
var earbudComponent;
var productDetailsComponent;
var headerComponent;
var isWellFormedRedirectPattern = false;
var isInvalidStore = false;

var storeIDPattern = /([0-9]+)(E|H)/i;

function applyCustomContentfulToken() { 
  const params = new URLSearchParams(window.location.search);
  var spaceID = params.get("SPACE_ID");
  var access_token = params.get("ACCESS_TOKEN");
  var preview_enabled = params.get("PREVIEW")
  if(preview_enabled) {
    showPreviewContent = true;
  }
  if((spaceID)&&(access_token)) {
    SPACE_ID = spaceID;
    ACCESS_TOKEN = access_token;
  }
}

applyCustomContentfulToken();


function getStoreParams() {
  if(storeNumber === '') {
    window.storeNumber = window.storeID;
  }
  let params = (new URL(document.location)).searchParams;
  var sn = params.get("storeID")
  var storeData = window.location.pathname.substring(1).split('/')[0];

  var result = storeData.match(storeIDPattern);;
  if (result == null)
    return { storeNumber: sn };
  var storeParams = { storeNumber: result[1], productType: result[2] }
  return storeParams;
}

var storeParams = getStoreParams();
if (storeParams.storeNumber) {
  storeNumber = storeParams.storeNumber
  window.storeID = storeNumber;
  window.storeName = storeParams.name;
}
if (storeParams.productType) {
  productType = storeParams.productType;
  window.productType = productType;
}

var urlParts = window.location.pathname.split('/');
isWellFormedRedirectPattern = storeIDPattern.test(urlParts[1]);;

function App() {
 
  function requestStoreData() {
    return storeContent;
  }




  useEffect(() => {
    const URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/?t=${new Date()}`;

    function runQuery(query) {
      return new Promise((resolve, reject) => {
        if(showPreviewContent)
          query = query.replace('preview: false', 'preview: true')


        const BODY = JSON.stringify({ query: query });
        window
          .fetch(URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
            body: BODY
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
            resolve(data);
          });
      })
    }

    function retrieveMediaUrls(storeData) {
      return new Promise((resolve, reject) => {
        var productList = [];
        storeData.productListCollection.items.forEach(p => {
          productList.push(p);
        });
        var queryList = []
        productList.forEach((p) => {
          var id = p.sys.id;
          var query = productMediaQuery.replace('_PRODUCT_ID_', id);
          queryList.push(runQuery(query));
        });
        Promise.all(queryList).then(values => {
          for (var i = 0; i < values.length; ++i) {
            productList[i].imagesCollection = values[i].product.imagesCollection;
          }
          resolve(values)          
        })
      });
    }

    runQuery(storeListQuery).then(storeData => {
      var store = storeData.storeCollection.items.find((item) => item.storeNumber === storeNumber);
      if (store == null) {
        isInvalidStore = true;
        store = storeData.storeCollection.items[0];
        store.invalid = true;        
        if(window.location.pathname !== '/404')          
          window.location.href = ('/404')
      }
      //store = storeData.storeCollection.items.find((item) => item.storeNumber === DEFAULT_SPACE);        

      runQuery(storeQuery.replace('_STORE_ID_', store.sys.id)).then(storeData => {
        store = storeData.store;
        retrieveMediaUrls(store).then(() => {
          storeContent = store;

          if (headerComponent) {
            headerComponent.updateStoreData(store);
          }
          if (productDetailsComponent) {
            productDetailsComponent.updateStoreData(store);

          }
          if (headphoneComponent)
            headphoneComponent.updateStoreData(store);          
          if (earbudComponent) {
            earbudComponent.updateStoreData(store);            
            //earbudComponent.children.updateStoreData(store); 
          }
        });
      })

    })
  }, []);

  /*
    isWellFormedRedirectPattern = true;
    productType = 'E';
    storeNumber = '2149';
    */


  
  
  if (isInvalidStore) {
    return (
      <Redirect to={'/404'} />
    )
  }
  return (
    <DebugRouter onChange="routeChanged">
      <Fragment>
        <div className={styles.app}>

          <div className={`5xl m-x-auto bg-green content-center`}>
            <Header ref={el => headerComponent = el} />
            <div>
              <Switch>
                <Route path="/product/:storeID/:productName" exact={false} key={window.location.pathname}
                  render={(prope) => <ProductDetails storeDataRequest={requestStoreData} ref={el => productDetailsComponent = el} storeNumber={storeNumber} key={window.location.pathname} />}
                >

                </Route>
                <Route path='/headphones' >
                  <AudioCatalog name="headphones" storeDataRequest={requestStoreData}  {...storeContent} productType={'headphones'} storeNumber={storeNumber} ref={el => headphoneComponent = el} />
                </Route>
                <Route path="/earbuds" >
                  <AudioCatalog name="earbuds" storeDataRequest={requestStoreData}  {...storeContent} productType={'earbuds'} storeNumber={storeNumber} ref={el => earbudComponent = el} />
                </Route>

                <Route path="/404">
                  <PageNotFound />
                </Route>

                <Route path="/" >
                  <Redirect to={isWellFormedRedirectPattern ? `/${(productType.toUpperCase() === 'E') ? 'earbuds' : 'headphones'}?storeID=${storeNumber}` : '/404'} />
                </Route>

                <Route >
                  <PageNotFound />
                </Route>
              </Switch>
              < Footer />
            </div>            
          </div>          
        </div>
        
      </Fragment>
    </DebugRouter>
  );
}

export default App;
