import { Link } from 'react-router-dom';
import React, { Component } from 'react';

export default class ProductSummary extends Component {

    render() { 
        console.log(this.props);
        const imageUrl = `${this.props.product.imagesCollection.items[0].url}?w=800`;
        return (
            <div className={"productSummary bg-gray p-4 m-5  mt-0 mb-10 pb-10 content-center"}>
                <Link 
                    to={{
                        pathname:`/product/${this.props.storeNumber}/${this.props.product.shortName}`,
                        product:this.props.product                        
                    }} 
                    key={Math.random()}
                >             
                    <img data-gtm-category="engage" data-gtm-action="explore more" data-gtm-label={this.props.product.name} className={"mx-auto"} src={imageUrl} alt={this.props.product.name} />
                    <div data-gtm-category="engage" data-gtm-action="explore more" data-gtm-label={this.props.product.name}  className={"text-center text-3xl font-telebold text-darkgray m-2"}>{this.props.product.name}</div>                
                    <div data-gtm-category="engage" data-gtm-action="explore more" data-gtm-label={this.props.product.name}  className="bg-brandPink text-white text-center w-28 mx-auto  rounded-full font-telemedium align-center">explore more &gt;</div>
                </Link>
            </div>

        )
    }

}