import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import tmologo from '../../assets/images/tmo-logo-v3.svg';

// import styles from './header.module.scss';
export default class Header extends Component {

  constructor(props) {
    super(props);
    this.updateStoreData({storeNumber:'0000'})
  }

  updateStoreData(data) {
    this.setState(data)
  }

  render() {

    var onErrorPage = window.location.pathname === "/404"
    
    if(this.state == null)
      return (
        <div>
   
        </div>
      )
    return (
      <div>
        {/* This example requires Tailwind CSS v2.0+ */}
        <nav className="bg-transparent text-white">
          <div className="bg-brandPink max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="grid grid-gap-4 grid-cols-2 max-w-5xl">
              <Link className={`bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium`} to={`/headphones?storeID=${this.state.storeNumber}`} >
                <img alt="T-Mobile Logo" className={"block t-mobile-logo"} src={tmologo} />
              </Link>
              <Link className={`${onErrorPage?"hidden":""} t-mobile-home font-telemediu3 align-middle  text-right items-center bg-gray-900 text-white px-3 pt-4 py-2 text-2xl content-evenly`} to={`/headphones?storeID=${this.state.storeNumber}`}>Home</Link>
            </div>

          </div>
        </nav>
      </div>
    );
  }
}
