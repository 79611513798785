import { Fragment } from 'react';
import React, { Component } from 'react';
import ProductSummary from '../../components/ProductSummary'
import ProductTypeSelection from '../../components/ProductTypeSelection';




function ProductList(props) {
  const products = props.products;
  const currentProductType = props.productType;
  window.pushDataLayer('',false, currentProductType);
  const items = products.map((productEntry) => {
    if (currentProductType.toLowerCase()[0] === productEntry.productType.toLowerCase()[0]) {
      return (<div key={`productlist_${currentProductType}(${Math.random()})`} ><ProductSummary product={productEntry} storeNumber={props.storeNumber} /></div>);
    } else {
      return '';
    }

  }
  );
  return (
    <div className="max-w-5xl mx-auto bg-white lg:pl-8 lg:pr-8 pb-1">{items}</div>
  );
}

class AudioCatalog extends Component {

  constructor(props) {
    super(props);
    this.productType = props.productType;
    this.storeDataRequest = props.storeDataRequest;

  }
  renderState = null;
  productType = null;
  storeDataRequest = () => { };
  state = {
    productListCollection: { items: [] }
  };

  ChildComponent = (props) => {
    return <p>I'm the 1st child! {props}</p>;
  };

  componentDidMount() {
    var data = this.storeDataRequest();
    this.renderState = data;
    this.setState(data)
  }
  componentWillReceiveProps(nextProps) {
    var data = this.storeDataRequest();
    this.renderState = data;
    this.setState(data);
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.renderState == null) {
      var data = this.storeDataRequest();
      this.renderState = data;
      this.setState(data);
    }
  }

  updateStoreData(data) {
    this.renderState = data;
    this.setState(data)
  }

  render() {
    if (this.renderState == null) {
      return <div></div>;
    }    
    return (
      <Fragment>
        <div className={`bg-black`}>
          <div className="max-w-5xl mx-auto bg-white">
            <ProductTypeSelection storeNumber={this.renderState.storeNumber} productType={this.productType} />
            <div className={`p-2 pt-2 pb-0 text-xl font-teleregular  ml-9 mr-9 text-brandPink`}>
            Learn about our latest audio products and accessories below. 
            </div>
            <div>
              {this.props.storeData}
            </div>
          </div>
          <ProductList products={this.state.productListCollection.items} storeNumber={this.renderState.storeNumber} productType={this.props.productType} key={Math.random()} />
        </div>
      </Fragment>
    );
  }
}


export default (AudioCatalog);