
export const storeListQuery =
  `{
    storeCollection(preview: false) {
			items {
        sys {
          id
        }
        name,
        storeNumber
    	}
    }
  }`;

  
export const storeQuery =
  `{
    store (id: "_STORE_ID_", preview: false){
      name,
      storeNumber,
      productListCollection {
        items {
          sys {
            id
          },
          name,
          shortName,
          productType,
          description,
          features,
          productColorsCollection {
            items {
              displayName,
              colorCode
            }
          },   
        }
      }
    }
  }`

export const productQuery = `{
    product (id: "2ebPiQKLaxl7wOEdxhp4k5", preview: false) {
      sys {
        id
      },
      shortName,
      name,
      features,
      productColorsCollection {
        items {
          name, displayName, colorCode
        }
      }
      productType,
      imagesCollection {
        items {
         name,
          picture {
            sys {
              id
            },
            url
          }
        }
      } 
    }
  }`

export const productMediaQuery = `{
	product(id: "_PRODUCT_ID_", preview: false ) {
    sys {
      id
    }
    name,
    imagesCollection {
      items {
       url
      }
    }
  }
}`

const Queries = { productMediaQuery:productMediaQuery, productQuery, storeQuery, storeListQuery}; 
export default Queries;