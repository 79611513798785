import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SquareBulletPoint from '../../assets/images/SquareBulletPoint.svg';


function ColorList(props) {
    const colors = props.colors;

    const items = colors.map((colorEntry) => {
        const divStyle = {
            "width":"35px",
            "height":"35px",
            "backgroundColor": colorEntry.colorCode,
            "marginTop":"5px",
            "border": "0.5px solid #C0C0C0"
        }
        
        return (<div className={"grid grid-cols-2 grid-gap-1 content-center"} key={colorEntry.displayName}>
            <div className={"p-1 justify-self-end"} >
                <div className={"color-swatch p-1 justify-self-end"} key={`$swatch_${colorEntry.displayName}`} style={divStyle}></div>
            </div>
            
            <div  className={"justify-left p-3 font-telebold align-middle  text-darkgray text-3xl"} key={`$label_${colorEntry.displayName}`} >{colorEntry.displayName}</div>
        </div>)
    }
    );
    return (
        <div key={`ColorList_${Math.random()}`}>{items}</div>
    );
}

function FeatureList(props) {
    const featureList = props.features;
    console.log(featureList);
    const items = featureList.map((feature) => {
        return (<li className={"feature text-2xl items-start pb-7"} key={feature}>
            <div className={"grid grid-cols-8 content-end"}>
              <div className={"pt-2 mx-auto col-span-1 items-start align-top resize-none"} >
                <img style={{height: "1em"}} alt="Bullet"  src={SquareBulletPoint} />
              </div>
                
              <div className={"col-span-7 text-brandPink font-telebold"}>
                {feature}
              </div>
            </div>
          </li>)
    });

    return (<ul className={" p-4 pl-10"}>
        {items}
    </ul>)
}

function CarouselWrapper(props) {
    const imageList = props.items;
    const name = props.name;
    const items = imageList.map((img, index) => {
        return (
            <div key={img.url}>
                <img data-gtm-category="engage" data-gtm-action="thumbnail click"  data-gtm-label={`${name}:slide item ${index + 1}`} key={`${name}:${index}`}  alt="product" src={`${img.url}?w=800`} />
            </div>
        )
    });
    return (
        <div className={"relative"}>
            <Carousel Key={Math.random()} interval={30000}  >
                {items}
            </Carousel>
        </div>
    )
}


class ProductDetails extends Component {
    storeID = '';
    productID = '';

    constructor(props) {
        super(props);
        this.showState.bind(this);
        this.state = 
        { 
            productListCollection: {items:[]},
            productColorsCollection: {items:[]}
        
             
        };
        
        //this.setState(this.state);
        if(props.storeDataRequest) {
            this.storeDataRequest = props.storeDataRequest;
        }
        this.forceUpdate.bind(this);
    }

    updateStoreData(data) { 
        this.setState(data)
    }

    storeDataRequest = ()=>{}

    componentDidMount() {
        
        if(this.props.match) {
            const params = this.props.match.params;
            this.storeID = params.storeID;
            this.productID = params.productID;
        } else {
            const path = document.location.pathname.split('/');
            this.storeID = path[2];
            this.productID = decodeURI(path[3]);
            var x = this.storeDataRequest();
            this.setState(x)
        }
        //this.fetchData(id);
    }
        
    showState(e) {
        //console.log('state', this.state);
    }
    render() {
        if (this.state == null) {
            return <div></div>;
        }

        const productList = this.state.productListCollection.items;
        function getProductInfo(productName) {
            var targetIndex = -1;
            var targetItem = null;
            var nextItem = null;
            var prevItem = null;

            //Find the target item index.
            for (var i = 0; i < productList.length && targetIndex === -1; ++i) {
                if (productList[i].shortName.trim() === productName.trim()) {
                    targetIndex = i;
                    targetItem = productList[i];
                }
            }

            var after = productList.slice(i );
            var forward = productList.slice(0, targetIndex);
            nextItem = after.find((x) => { 
                return x.productType === targetItem.productType;
            });
            if (nextItem == null) {
                nextItem = forward.find((x) => {
                    return x.productType === targetItem.productType;
                });
            }
            prevItem = forward.reverse().find((x)=>x.productType === targetItem.productType);
            if( prevItem == null) {
                prevItem = after.reverse().find((x)=> {
                    return x.productType === targetItem.productType;
                })
            }
            
            return [targetItem, nextItem, prevItem];
        }
        var [product, nextItem, prevItem] = getProductInfo(this.productID);

        if(product == null)
        return ("<div></div>")
        if(nextItem == null)
            nextItem = product;
        if(prevItem == null)
            prevItem = product;
        
        var prevPath= `/product/${this.storeID}/${prevItem.shortName}`;
        var nextPath = `/product/${this.storeID}/${nextItem.shortName}`

        if(product == null) {
            return <div></div>;
        }
        var showColors = (product.productColorsCollection.items!=null) && (product.productColorsCollection.items.length>1);
        window.scrollTo(0, 0);        
        window.pushDataLayer(product.shortName,false, product.productType.toLowerCase());

        return (

            <div className={"productDetailsContainer bg-white max-w-5xl mx-auto"}>
                <div className="grid grid-cols-2 max-w-5xl mx-auto">
                    <Link data-gtm-category="engage" data-gtm-action="previous product link click" data-gtm-label={prevItem.shortName} onClick={this.showState} className={`p-3 text-left product-previous`}    to={{pathname: prevPath}} itemID={prevItem.shortName}> &lt; previous product</Link>                    
                    <Link data-gtm-category="engage" data-gtm-action="next product link click"      data-gtm-label={nextItem.shortName}  onClick={this.showState}  className={"p-3 text-right product-next"}  to={{pathname: nextPath}}  itemID={nextItem.shortName} key={Math.random()} >next product &gt;</Link>
                </div>

                <CarouselWrapper items={product.imagesCollection.items} name={product.shortName} key={Math.random()} />
                <div className={"text-4xl text-center  text-darkgray p-2 pb-4 font-telebold"}>{product.shortName}</div>
                <div className={`${(showColors?"":"hidden")} mx-auto pb-3 text-center font-telebold text-brandPink text-3xl`}>Other colors available</div>
                <div className={`${(showColors?"":"hidden")} pb-3`}>
                    <ColorList  colors={product.productColorsCollection.items} />
                </div>
                <div className={"font-telethin text-xl text-brandPink  text-2xl ml-12 pl-2 pr-2"}>{product.description}</div>
                <FeatureList features={product.features} />
            </div>

        )
    }

}

//Passing this filtered through "withRouter" gives it the ability to 
//read path aprameters. But that also cuts it off from some state data 
//that is necessary. To resolve the conflict, I am not using withRouter
//and am reading the information manually.
export default (ProductDetails);